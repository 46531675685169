import React, { Component } from 'react'

import Logo from '../assets/images/logo-outlined.svg'

class Cta extends Component {

  render() {
    const { backgroundColour, backgroundImage, title } = this.props
    return (
      <section className='cta' style={{ backgroundColor: `${backgroundColour}` }}>
        <div className='cta__inner'>
          <div className='cta__content'>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        <img className='cta__logo' src={Logo} alt='Mara+Co' />
        { backgroundImage && <img className='cta__image' src={backgroundImage?.localFile?.childImageSharp.original.src} alt='Mara+Co' /> }
      </section>
    )
  }
}

export default Cta